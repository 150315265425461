import React, { useState } from 'react';

function App() {
  const srcBase = 'https://lookerstudio.google.com/reporting/create?' +
    'c.reportId=79fb4dad-f538-48d5-baa4-655aac58b8f5&c.mode=edit' +
    '&r.reportName=Reporte General - EmpresaPrueba' +
    '&ds.*..refreshFields=true&ds.*.type=CUSTOM_QUERY&ds.*.keepDatasourceName=true&ds.*.sqlReplace=@empresa,\'EmpresaPrueba\'';

  const [newSrc, setNewSrc] = useState(srcBase);
  const [newCompanyName, setNewCompanyName] = useState('Empresa de Prueba');

  const handleSrcChange = (e) => {
    setNewCompanyName(e.target.value);
  };

  const handleOpenInNewTab = () => {

    setNewSrc(srcBase.replace(/EmpresaPrueba/g, newCompanyName))
  };

  const openInNewTab = () => {
    window.open(newSrc, '_blank');
  };

  const copyTextToClipboard = () => {
    navigator.clipboard.writeText(newSrc);
  };

  return (
    <div className="App" style={{ margin: '0 auto', textAlign: 'center', marginTop: '50px' }}>
      <header className="App-header">
        <h1 style={{ marginBottom: '20px' }}>Generar reportes Monkeyfit</h1>
      </header>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <label htmlFor="newUrl" style={{ marginBottom: '10px', display: 'block' }}>Ingresa la nueva URL:</label>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="text"
          id="newUrl"
          value={newCompanyName}
          onChange={handleSrcChange}
          style={{ marginBottom: '10px', padding: '5px' }}
        />
        <button
          onClick={handleOpenInNewTab}
          style={{ marginRight: '10px', padding: '10px 20px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '5px' }}>
          Crear link de reporte
        </button>
        <button
          onClick={openInNewTab}
          style={{ padding: '10px 20px', backgroundColor: '#008CBA', color: 'white', border: 'none', borderRadius: '5px' }}>
          Abrir link de reporte
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <input
          type="text"
          id="srcUrl"
          value={newSrc}
          style={{ marginBottom: '10px', padding: '5px', width: '200px' }}
          readOnly
        />
      </div>
      <button onClick={copyTextToClipboard} style={{ marginLeft: '10px', padding: '10px 20px', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '5px' }}>Copiar texto</button>

    </div>
  );
}

export default App;
